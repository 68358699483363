<template>
  <v-stepper 
    v-model="step"
    flat
    class="transparent custom-header"
    alt-labels
    >
        <!-- Header -->
    <v-stepper-header>
      <!-- Header: Step 1 -->
      <v-stepper-step
        :complete="step > 1"
        step="1"
        color="secondary"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span class="text--secondary text-sm font-weight-semibold">Account Details</span>
          <span class="text--secondary text-xs">Setup your account details</span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 2 -->
      <v-stepper-step
        :complete="step > 2"
        step="2"
        color="secondary"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span class="text--secondary text-sm font-weight-semibold">Personal Info</span>
          <span class="text--secondary text-xs">Setup Information</span>
        </div>
      </v-stepper-step>

      <v-divider></v-divider>

      <!-- Header: Step 3 -->
      <v-stepper-step 
      step="3"
      color="secondary"
      >
        <div class="d-flex flex-column text-center text-no-wrap">
          <span class="text--secondary text-sm font-weight-semibold">Review &nbsp; Submit</span>
          <span class="text--secondary text-xs">Write a Review</span>
        </div>
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1" class="step1">
        <v-row>
          <v-col cols="12">
              <div class="headings text-center">
                <h1>Цель</h1>
                <h2>Какая Ваша главная цель?</h2>
              </div>
          </v-col>
        </v-row>
        <v-item-group>
          <v-container>
            <v-row>
              <v-col
                cols="12" md="6"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    :color="active ? 'secondary' : ''"
                    @click="toggle"
                    class="d-flex"
                  >
                  <div class="flex-grow-1">
                    <v-card-title>Похудеть</v-card-title>
                    <v-card-subtitle>Сжигание жира и калорий</v-card-subtitle>
                  </div>
      
                  <v-img src="@/assets/images/stepper/lose-weight.png"></v-img>
                  </v-card>
                </v-item>
              </v-col>
              <v-col
               cols="12" md="6"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    :color="active ? 'secondary' : ''"
                    @click="toggle"
                    class="d-flex"
                  >
                  <div class="flex-grow-1">
                    <v-card-title>Развить полезные привычки</v-card-title>
                    <v-card-subtitle>Без голоданий и ночных перекусов</v-card-subtitle>
                  </div>
      
                  <v-img src="@/assets/images/stepper/healthy-habits.png"></v-img>
                  </v-card>
                </v-item>
              </v-col>
              <v-col
                cols="12" md="6"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    :color="active ? 'secondary' : ''"
                    @click="toggle"
                    class="d-flex"
                  >
                  <div class="flex-grow-1">
                    <v-card-title>Больше энергии</v-card-title>
                    <v-card-subtitle>Почувствуйте себя лучше и бодрее</v-card-subtitle>
                  </div>
      
                  <v-img src="@/assets/images/stepper/more-energy.png"></v-img>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
        <div class="d-flex justify-end">
          <v-btn
            color="primary"
            @click="step = 2"
          >
            Next
          </v-btn>
        </div>
      </v-stepper-content>
      <v-stepper-content step="2" class="step2">
        <v-container>
          <v-row>
            <v-col cols="12">
                <div class="headings text-center">
                  <h1>Выберите тип вашей фигуры</h1>
                  <h2>У каждого типа телосложения свои метаболические параметры.</h2>
                </div>
            </v-col>
          </v-row>
        </v-container>
        <v-item-group>
          <v-container>
            <v-row 
            align="center"
            justify="center">
              <v-col cols="12" lg="6">
                <v-row>
                  <v-col cols="3">
                    <v-item v-slot="{ active, toggle }">
                      <div>
                        <v-card
                          :color="active ? 'secondary' : ''"
                          @click="toggle"
                          class="d-flex flex-column"
                        >
                          <v-img src="@/assets/images/stepper/rectangle.png"></v-img>
                        </v-card>
                        <div>
                          <v-card-title>Прямо <br/>угольная</v-card-title>
                        </div>
                      </div>
                    </v-item>
                  </v-col>
                  <v-col
                    cols="3"
                  >
                    <v-item v-slot="{ active, toggle }">
                      <div>
                        <v-card
                          :color="active ? 'secondary' : ''"
                          @click="toggle"
                          class="d-flex flex-column"
                        >
                          <v-img src="@/assets/images/stepper/round.png"></v-img>
                        </v-card>
                        <div class="flex-grow-1">
                          <v-card-title>Яблоко</v-card-title>
                        </div>
                      </div>
                    </v-item>
                  </v-col>
                  <v-col
                    cols="3"
                  >
                    <v-item v-slot="{ active, toggle }">
                      <div>
                        <v-card
                          :color="active ? 'secondary' : ''"
                          @click="toggle"
                          class="d-flex flex-column"
                        >
                          <v-img src="@/assets/images/stepper/houglass.png"></v-img>
                        </v-card>
                        <div>
                          <v-card-title>Песочные часы</v-card-title>
                        </div>
                      </div>
                    </v-item>
                  </v-col>
                  <v-col
                    cols="3"
                  >
                    <v-item v-slot="{ active, toggle }">
                      <div>
                      <v-card
                        :color="active ? 'secondary' : ''"
                        @click="toggle"
                        class="d-flex flex-column"
                      >
                      <v-img src="@/assets/images/stepper/pear.png"></v-img>
                      </v-card>
                      <div class="flex-grow-1">
                        <v-card-title>Груша</v-card-title>
                      </div>
                      </div>
                    </v-item>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
        <div class="d-flex justify-end">
          <v-btn
            color="primary"
            @click="step = 3"
          >
            Next
          </v-btn>
        </div>
      </v-stepper-content>
      <v-stepper-content step="3" class="step3">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="headings text-center">
                <h1>Выберите свою проблемную зону</h1>
                <h2>Наша программа тренировок будет сосредоточена на этой зоне?</h2>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-item-group multiple>
          <v-container>
            <v-row
            align="center"
            justify="center"
            >
              <v-col
                cols="12" md="6"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    :color="active ? 'secondary' : ''"
                    @click="toggle"
                    class="d-flex"
                  >
                  <div class="flex-grow-1">
                    <v-card-title>Руки</v-card-title>
                  </div>
                </v-card>
                </v-item>
              </v-col>
            </v-row>
            <v-row align="center"
            justify="center">
              <v-col
                cols="12" md="6"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    :color="active ? 'secondary' : ''"
                    @click="toggle"
                    class="d-flex"
                  >
                  <div class="flex-grow-1">
                    <v-card-title>Пресс</v-card-title>
                  </div>
                </v-card>
                </v-item>
              </v-col>
            </v-row>
            <v-row align="center"
            justify="center">
              <v-col
                cols="12" md="6"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    :color="active ? 'secondary' : ''"
                    @click="toggle"
                    class="d-flex"
                  >
                  <div class="flex-grow-1">
                    <v-card-title>Ягодицы и Бедра</v-card-title>
                  </div>
                </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </v-stepper-content>
      <v-stepper-content step="4" class="step4">
        <v-container>
          <v-row>
            <v-col cols="6">
              <div class="headings text-center">
                <h1>Ваш вес</h1>
              </div>
            </v-col>
          </v-row>
          <div class="d-flex">
            <v-text-field type="number">
              <div
                slot="append-outer"
                color="red"
              >
                Кг
              </div>
            </v-text-field>
          </div>
          <v-row>
            <v-col cols="6">
              <div class="headings text-center">
                <h1>Ваш вес</h1>
              </div>
            </v-col>
          </v-row>
          <div class="d-flex">
            <v-text-field type="number">
              <div
                slot="append-outer"
                color="red"
              >
                Кг
              </div>
            </v-text-field>
          </div>
        </v-container>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
  export default {
    name: 'Home',
    data: () => ({
      step: 1,
        items: [
        {
          icon: 'mdi-inbox',
          text: 'Inbox',
        },
        {
          icon: 'mdi-star',
          text: 'Star',
        },
        {
          icon: 'mdi-send',
          text: 'Send',
        },
        {
          icon: 'mdi-email-open',
          text: 'Drafts',
        },
      ],
    }),
  }
</script>

<style scoped lang="scss">
#app { 
  .headings {
    margin: 25px 0;

    h1 {
      font-size: 2rem;
    }
    h2 {
      font-weight: 400;
    }
  }

.v-stepper__content {
  padding: 0;
}

  .v-card__title, .v-card__subtitle  {
    color: #000;
    font-size: .8rem;
  }

  .v-item--active {
    .v-card__title, .v-card__subtitle {
      color: #fff;
    }
  }

  .step1 {
    .v-image {
      max-width: 22%;
    }
  }

  .step2 {
    .v-card__title, .v-card__subtitle  {
      text-align: center;
      padding: 5px 0;
      width: 100%;
      display: block;
      line-height: 1.7;

    }
  }
  .v-item-group.v-bottom-navigation {
    box-shadow: none;

    button {
      width: 25px;
      color:rgba(0, 0, 0, 0.87) !important;
    }
  }
}

.custom-header.v-stepper .v-divider::after {
  background: var(--v-secondary-base);
}
</style>
