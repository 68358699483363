var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stepper',{staticClass:"transparent custom-header",attrs:{"flat":"","alt-labels":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.step > 1,"step":"1","color":"secondary"}},[_c('div',{staticClass:"d-flex flex-column text-center text-no-wrap"},[_c('span',{staticClass:"text--secondary text-sm font-weight-semibold"},[_vm._v("Account Details")]),_c('span',{staticClass:"text--secondary text-xs"},[_vm._v("Setup your account details")])])]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.step > 2,"step":"2","color":"secondary"}},[_c('div',{staticClass:"d-flex flex-column text-center text-no-wrap"},[_c('span',{staticClass:"text--secondary text-sm font-weight-semibold"},[_vm._v("Personal Info")]),_c('span',{staticClass:"text--secondary text-xs"},[_vm._v("Setup Information")])])]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3","color":"secondary"}},[_c('div',{staticClass:"d-flex flex-column text-center text-no-wrap"},[_c('span',{staticClass:"text--secondary text-sm font-weight-semibold"},[_vm._v("Review   Submit")]),_c('span',{staticClass:"text--secondary text-xs"},[_vm._v("Write a Review")])])])],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"step1",attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"headings text-center"},[_c('h1',[_vm._v("Цель")]),_c('h2',[_vm._v("Какая Ваша главная цель?")])])])],1),_c('v-item-group',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex",attrs:{"color":active ? 'secondary' : ''},on:{"click":toggle}},[_c('div',{staticClass:"flex-grow-1"},[_c('v-card-title',[_vm._v("Похудеть")]),_c('v-card-subtitle',[_vm._v("Сжигание жира и калорий")])],1),_c('v-img',{attrs:{"src":require("@/assets/images/stepper/lose-weight.png")}})],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex",attrs:{"color":active ? 'secondary' : ''},on:{"click":toggle}},[_c('div',{staticClass:"flex-grow-1"},[_c('v-card-title',[_vm._v("Развить полезные привычки")]),_c('v-card-subtitle',[_vm._v("Без голоданий и ночных перекусов")])],1),_c('v-img',{attrs:{"src":require("@/assets/images/stepper/healthy-habits.png")}})],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex",attrs:{"color":active ? 'secondary' : ''},on:{"click":toggle}},[_c('div',{staticClass:"flex-grow-1"},[_c('v-card-title',[_vm._v("Больше энергии")]),_c('v-card-subtitle',[_vm._v("Почувствуйте себя лучше и бодрее")])],1),_c('v-img',{attrs:{"src":require("@/assets/images/stepper/more-energy.png")}})],1)]}}])})],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.step = 2}}},[_vm._v(" Next ")])],1)],1),_c('v-stepper-content',{staticClass:"step2",attrs:{"step":"2"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"headings text-center"},[_c('h1',[_vm._v("Выберите тип вашей фигуры")]),_c('h2',[_vm._v("У каждого типа телосложения свои метаболические параметры.")])])])],1)],1),_c('v-item-group',[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"color":active ? 'secondary' : ''},on:{"click":toggle}},[_c('v-img',{attrs:{"src":require("@/assets/images/stepper/rectangle.png")}})],1),_c('div',[_c('v-card-title',[_vm._v("Прямо "),_c('br'),_vm._v("угольная")])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"color":active ? 'secondary' : ''},on:{"click":toggle}},[_c('v-img',{attrs:{"src":require("@/assets/images/stepper/round.png")}})],1),_c('div',{staticClass:"flex-grow-1"},[_c('v-card-title',[_vm._v("Яблоко")])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"color":active ? 'secondary' : ''},on:{"click":toggle}},[_c('v-img',{attrs:{"src":require("@/assets/images/stepper/houglass.png")}})],1),_c('div',[_c('v-card-title',[_vm._v("Песочные часы")])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"color":active ? 'secondary' : ''},on:{"click":toggle}},[_c('v-img',{attrs:{"src":require("@/assets/images/stepper/pear.png")}})],1),_c('div',{staticClass:"flex-grow-1"},[_c('v-card-title',[_vm._v("Груша")])],1)],1)]}}])})],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.step = 3}}},[_vm._v(" Next ")])],1)],1),_c('v-stepper-content',{staticClass:"step3",attrs:{"step":"3"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"headings text-center"},[_c('h1',[_vm._v("Выберите свою проблемную зону")]),_c('h2',[_vm._v("Наша программа тренировок будет сосредоточена на этой зоне?")])])])],1)],1),_c('v-item-group',{attrs:{"multiple":""}},[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex",attrs:{"color":active ? 'secondary' : ''},on:{"click":toggle}},[_c('div',{staticClass:"flex-grow-1"},[_c('v-card-title',[_vm._v("Руки")])],1)])]}}])})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex",attrs:{"color":active ? 'secondary' : ''},on:{"click":toggle}},[_c('div',{staticClass:"flex-grow-1"},[_c('v-card-title',[_vm._v("Пресс")])],1)])]}}])})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex",attrs:{"color":active ? 'secondary' : ''},on:{"click":toggle}},[_c('div',{staticClass:"flex-grow-1"},[_c('v-card-title',[_vm._v("Ягодицы и Бедра")])],1)])]}}])})],1)],1)],1)],1)],1),_c('v-stepper-content',{staticClass:"step4",attrs:{"step":"4"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"headings text-center"},[_c('h1',[_vm._v("Ваш вес")])])])],1),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"type":"number"}},[_c('div',{attrs:{"slot":"append-outer","color":"red"},slot:"append-outer"},[_vm._v(" Кг ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"headings text-center"},[_c('h1',[_vm._v("Ваш вес")])])])],1),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"type":"number"}},[_c('div',{attrs:{"slot":"append-outer","color":"red"},slot:"append-outer"},[_vm._v(" Кг ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }